import React, { useContext } from "react";

import { Link, navigate } from "gatsby";

import { getExperts_getExperts_edges_node_skills } from "@/components/catalog/graphql/__generated__/getExperts";
import CallButtons from "@/components/common/callButtons";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { useMarketingHook } from "@/marketing/marketingHook";
// import { getFavorites, addOrRemoveFavorite } from "@/account/favorites";
import { isBrowser } from "@/utils/env";
import { capitalize, cleanupFromTags } from "@/utils/stringUtils";

import ExpertStatus from "../expertStatus";
import Rating from "../rating";
import { UserAvatar } from "../userAvatar";
import { UserAvatarTypeEnum } from "../userAvatar/types";

import { ExpertCardProps } from "./types";
import "./styles.scss";

const ExpertCard = ({ expert, expertCardPosition }: ExpertCardProps) => {
  const { marketingExpertCardClickHandler } = useMarketingHook();
  
  const { isUserLoggedIn, freeMinutesCount } = useContext<UserContextType>(UserContext);

  const handleExpertCardClick = (isBlockClick:boolean = true) => {
    marketingExpertCardClickHandler(
      expert.id,
      expert.name,
      expert.rates.videoCall,
      expert.skills?.map((skill) =>
        skill.badge) || [],
      Number(window.location.pathname),
      expertCardPosition?.toString(),
    );

    if (isBlockClick) {
      navigate(`/expert/${expert.id}`, { state: { prevPath: window.location.pathname } });
    }
  };

  // We show only the first paragpaph
  const clearedDescription = expert.description.substring(
    expert.description.indexOf("<p>"),
    expert.description.indexOf("</p>") + 4,
  );

  return (
    <div className="expert-card">
      <div
        className="expert-card__avatar"
        onClick={() =>
          handleExpertCardClick()}
      >
        <UserAvatar
          mobileImage={expert.mobileImage}
          desktopImage={expert.desktopImage}
          name={expert.name}
          userId={expert.id}
          type={UserAvatarTypeEnum.Catalog}
        />
      </div>
      <div className="expert-card__info">
        <div className="expert-card__name">
          <Link
            to={`/expert/${expert.id}`}
            state={{ prevPath: isBrowser() ? window.location.pathname : "" }}
            onClick={() =>
              handleExpertCardClick(false)}
          >
            <h3>{expert.name}</h3>
          </Link>
        </div>
        <div className="expert-card__subinfo">
          <Rating value={expert.rating} />
          <span className="expert-card__skills">
            {capitalize(
              ([...(expert.skills || [])]
                ?.sort((a, b) => // todo: remove after https://3.basecamp.com/5069474/buckets/26069459/todos/6586058560
                  (b.badge === "Таролог" ? 1 : -1))
                ?? [])
                .map((skill: getExperts_getExperts_edges_node_skills) =>
                  skill.badge)
                .join(", "),
            )}
          </span>
        </div>
        <div className="expert-card__status">
          <ExpertStatus status={expert.state} />
        </div>
        <div
          className="expert-card__description"
          onClick={() =>
            handleExpertCardClick()}
        >
          <div
            className="article expert-card__desc"
            dangerouslySetInnerHTML={{
              __html: `<noindex>${cleanupFromTags(clearedDescription)}</noindex>`,
            }}
          />
          <div className="expert-card__links">
            <div
              className="expert-card__profile"
              onClick={() =>
                handleExpertCardClick()}
            >
              Перейти в&nbsp;профиль
            </div>
          </div>
        </div>
      </div>
      <div className="expert-card__bottom">
        <CallButtons
          expertId={expert.id}
          expertRates={expert.rates}
          expertState={expert.state}
          freeMinutesCount={freeMinutesCount}
          isUserLoggedIn={isUserLoggedIn}
          trialAllowance={expert.trialAllowance}
        />
      </div>
    </div>
  );
};

export default ExpertCard;
